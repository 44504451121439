import React from 'react'
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import LayoutStatic from '../../../components/LayoutStatic';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    width: '100%'
  }
}

const IndexPage = () => {
  return (
    <LayoutStatic>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <div className="card w-100 mt-1">
            <img src="/img/Sensory.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">แบบเช็คระบบประสาทรับความรู้สึก</h5>
              <p className="card-text">(Sensory Checklist) สำหรับเด็กอายุ 2-8 ปี</p>
              <Link to="/questionnaire?questionnaireId=65" className="btn btn-primary">ระบบการรับสัมผัส(Tactile System)</Link>
              <Link to="/questionnaire?questionnaireId=66" className="btn btn-primary" style={style.button}>ระบบการทรงตัวและการเคลื่อนไหว (Vestibular System)</Link>
              <Link to="/questionnaire?questionnaireId=68" className="btn btn-primary" style={style.button}>ระบบการรับรู้ เอ็น กล้ามเนื้อ ข้อต่อ (ProprioceptiveSystem)</Link>
              <Link to="/questionnaire?questionnaireId=67" className="btn btn-primary" style={style.button}>ระบบการมองเห็น (Vitual System)</Link>
              <Link to="/questionnaire?questionnaireId=69" className="btn btn-primary" style={style.button}>ระบบการได้ยิน(Auditory System)</Link>
              {/* <Link to="/questionnaire?questionnaireId=30" className="btn btn-primary" style={style.button}>ประเมินคัดกรองโรคสมาธิสั้น</Link> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </LayoutStatic>
  );
}

export default IndexPage

